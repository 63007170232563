// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-about-js": () => import("/Users/themusicman/Projects/Websites/harmonichemispheres.github.io/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("/Users/themusicman/Projects/Websites/harmonichemispheres.github.io/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-js": () => import("/Users/themusicman/Projects/Websites/harmonichemispheres.github.io/src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-projects-music-js": () => import("/Users/themusicman/Projects/Websites/harmonichemispheres.github.io/src/pages/projects/music.js" /* webpackChunkName: "component---src-pages-projects-music-js" */),
  "component---src-pages-research-js": () => import("/Users/themusicman/Projects/Websites/harmonichemispheres.github.io/src/pages/research.js" /* webpackChunkName: "component---src-pages-research-js" */),
  "component---src-pages-writings-js": () => import("/Users/themusicman/Projects/Websites/harmonichemispheres.github.io/src/pages/writings.js" /* webpackChunkName: "component---src-pages-writings-js" */)
}

